<template>
    <div class="gst-not-found d-flex flex-column align-center justify-center">
        <div class="gst-not-found__countdown-container">
            <Rotated class="gst-not-found__countdown-container__rotated" />
            <CircleOutline class="gst-not-found__countdown-container__circle" />
            <div class="gst-not-found__countdown-container__countdown">
                {{ timerCount }}
            </div>
        </div>
        <p class="gst-not-found__title">
            {{ $t( 'title' ) }}
        </p>
        <p class="gst-not-found__subtitle">
            {{ $t( 'subtitle' ) }}
        </p>
        <BaseButton
            class="gst-not-found__button"
            :placeholder="$t('button')" 
            @click="redirectToHomePage" />
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import Rotated from '@tenants/ticketmaster/app/assets/animations/rotated_circle.svg';
    import CircleOutline from '@tenants/ticketmaster/app/assets/animations/circle.svg';

    export default {
        name: 'NotFound',
        components: {
            BaseButton,
            Rotated,
            CircleOutline
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.notFound'
        },
        data( ) {
            return {
                timerCount: 5
            };
        },
        methods: {
            redirectToHomePage( ) {
                this.$router.push( { name: 'home' } );
            }
        },
        mounted() {
            this._intervalId = setInterval( ( ) => {
                if ( this.timerCount === 0 ) {
                    this.redirectToHomePage( );
                } 
                this.timerCount--;
            }, 1000 );
        },
        beforeDestroy( ) {
            clearInterval( this._intervalId );
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-not-found {
    padding: 100px 31px 31px;
    color: theme-color( 'new-quaternary' );
    font-family: "Averta Std", sans-serif;
    text-align: center;

    .gst-not-found__title {
        font-size: 32px;
        font-weight: bold;
    }

    .gst-not-found__subtitle {
        margin-bottom: 30px;
        font-size: 24px;
    }

    .gst-not-found__button {
        line-height: 20px;
        padding: 16px 17px;
        background-color: theme-color( 'new-primary' ) !important;
        color: white;
        font-size: 16px;
        font-weight: 600;
        text-transform: none;
    }

    .gst-not-found__countdown-container {
        position: relative;
        height: 88px;
        width: 88px;
        margin-bottom: 52px;

        .gst-not-found__countdown-container__rotated {
            animation: rotate 1s linear infinite;
            animation-delay: 0.25s;
        }

        .gst-not-found__countdown-container__circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
        }

        .gst-not-found__countdown-container__countdown {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 36px;
            transform: translate( -50%, -50% );
            animation: opacity_counter 5s forwards;
        }
    }

    @include mobile-only {
        .gst-not-found__title {
            font-size: 24px;
        }

        .gst-not-found__countdown-container {
            margin-bottom: 40px;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate( 0deg );
    }

    25% {
        transform: rotate( 30deg );
    }

    50% {
        transform: rotate( 30deg );
    }

    75% {
        transform: rotate( 30deg );
    }

    100% {
        transform: rotate( 30deg );
    }
}

@keyframes opacity_counter {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    20% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>